<!-- 短信记录查询 -->
<template>
  <div>
    <div class="searchAreaCss">
      <SearchArea
        :accountInfoList="accountInfoList"
        :searchParam="searchParam"
        :rid="rid"
        @initSendInfoList="initSendInfoList"
      />
    </div>
    <div>
      <a-button type="primary" @click.stop="doAddMessage">
        <MailOutlined />创建短信
      </a-button>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 1000 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="sendInfoList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #action="{ record }">
          <span>
            <a-button type="link" size="small" @click="doDetailMessage(record)">
              <FileTextOutlined />详情
            </a-button>
            <a-divider
              type="vertical"
              v-show="record.status < 3"
              style="border-color: #7cb305"
              dashed
            />
            <a-button
              type="link"
              size="small"
              v-show="record.status < 3"
              @click="doEditMessage(record)"
            >
              <EditOutlined />编辑
            </a-button>
            <a-divider
              type="vertical"
              v-show="record.status < 3"
              style="border-color: #7cb305"
              dashed
            />
            <a-button
              type="link"
              size="small"
              @click="doDeleteMessage(record)"
              v-show="record.status < 3"
            >
              <SvgIcon iconName="delete_red" className="svg15" />
              <span style="color: red">删除</span>
            </a-button>
            <a-divider
              type="vertical"
              v-show="record.status < 3"
              style="border-color: #7cb305"
              dashed
            />
            <a-button
              type="link"
              size="small"
              v-show="record.status < 3"
              @click="doSendMessage(record)"
            >
              <SettingOutlined />发送
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div>
      <MessageForm
        :modalVisible="modalVisible"
        :isCreate="isCreate"
        :searchParam="searchParam"
        :editData="editData"
        @handleModalVisible="handleModalVisible"
        @saveCallBack="saveCallBack"
      />
    </div>
  </div>
</template>
<script>
import { MailOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import {
  getSendInfoListPage,
  deleteSendInfo,
  doSendInfo,
} from "@/api/message/send";
import { getAccountInfoList } from "@/api/account/account";
import moment from "moment";
import SearchArea from "./components/mySearchArea.vue";
import MessageForm from "./components/MessageForm.vue";
import { message, Modal } from "ant-design-vue";
export default defineComponent({
  name: "SendInfo",
  components: {
    SearchArea,
    MessageForm,
    MailOutlined,
  },
  setup() {
    const state = reactive({
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      rid: JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id,
      searchParam: {
        pageIndex: 1,
        pageSize: 10,
        accountId:
          JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id == 3
            ? JSON.parse(sessionStorage.getItem("userinfo")).id
            : 0,
        sendStatus: 0,
        transMode: 0,
      },
      accountInfoList: [],
      sendInfoList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
      isCreate: 0, //0新增  1编辑  2详情
      modalVisible: false,
      editData: {
        id: null,
        tplId: 0,
        msgContent: "",
        msgReciver: "",
        ifEncrypt: 0,
        sendTime: null,
      },
    });
    const columns = [
      {
        title: "账户名称",
        dataIndex: "accountInfo.uname",
        key: "accountName",
        fixed: "left",
        width: 150,
      },
      {
        title: "接受者",
        dataIndex: "msgReciver",
        key: "apiName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "消息内容",
        dataIndex: "msgContent",
        key: "msgContent",
        ellipsis: true,
        width: 200,
      },
      {
        title: "发送时间",
        dataIndex: "SendTime",
        key: "SendTime",
        align: "center",
        width: 150,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "是否加密传输",
        dataIndex: "transModeInfo.item",
        key: "transMode",
        width: 150,
      },
      {
        title: "发送状态",
        dataIndex: "sendStatusInfo.item",
        key: "sendStatus",
        width: 150,
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: 300,
        fixed: "right",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      initSendInfoList(state.searchParam);
      initAccountInfoList();
    });
    const initAccountInfoList = () => {
      getAccountInfoList().then((resp) => {
        if (resp) {
          state.accountInfoList = resp;
        }
      });
    };
    const initSendInfoList = (searchParam) => {
      state.loading = true;
      getSendInfoListPage(searchParam).then((resp) => {
        if (resp) {
          state.sendInfoList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initSendInfoList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initSendInfoList(state.searchParam);
    };

    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const saveCallBack = (code) => {
      if (code == 200) {
        initSendInfoList(state.searchParam);
      }
    };
    const doAddMessage = () => {
      handleModalVisible(true);
      state.isCreate = 0;
      state.editData = {
        id: null,
        tplId: 0,
        msgContent: "",
        groupId: 0,
        msgReciver: "",
        ifEncrypt: 1,
        sendTime: null,
      };
    };
    const doEditMessage = (record) => {
      handleModalVisible(true);
      state.isCreate = 1;
      state.editData = {
        id: record.id,
        tplId: record.tplId,
        msgContent: record.msgContent,
        groupId: record.groupId,
        msgReciver: record.msgReciver,
        ifEncrypt: record.ifEncrypt,
        sendTime: record.sendTime,
      };
    };
    const doDetailMessage = (record) => {
      handleModalVisible(true);
      state.isCreate = 2;
      state.editData = {
        id: record.id,
        tplId: record.tplId,
        msgContent: record.msgContent,
        groupId: record.groupId,
        msgReciver: record.msgReciver,
        ifEncrypt: record.ifEncrypt,
        sendTime: record.sendTime,
      };
    };
    const doDeleteMessage = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将永久删除此条短信，是否继续？"
        ),
        onOk() {
          deleteSendInfo({ id: record.id, logic: true }).then((resp) => {
            if (resp) {
              initSendInfoList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消删除短信的操作！！！");
        },
      });
    };
    const doSendMessage = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将立即发送此条短信，是否继续？"
        ),
        onOk() {
          doSendInfo(record).then((resp) => {
            if (resp) {
              initSendInfoList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消发送短信操作！！！");
          initSendInfoList(state.searchParam);
        },
      });
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initSendInfoList,
      getAccountInfoList,
      onChangePage,
      onShowSizeChange,
      doAddMessage,
      doEditMessage,
      doDetailMessage,
      doDeleteMessage,
      doSendMessage,
      handleModalVisible,
      saveCallBack,
    };
  },
});
</script>
<style lang='scss' scoped>
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>